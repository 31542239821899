#google-translate {
    a {
        text-transform: uppercase;
        img {
            opacity: 0.5;
            transition: all 0.3s ease;
        }
        &.active, &:hover {
            img {
                opacity: 1;
            }
        }
    }
}

.gt .skiptranslate {
    display: none !important;
}

body.gt {
    top: 0 !important;
}


// a.flag {
//     text-decoration: none;
// }

// a.flag img {
//     vertical-align: middle;
//     padding: 0;
//     margin: 0;
//     border: 0;
//     display: inline;
//     height: 16px;
//     opacity: 0.8;
// }

// a.flag:hover img {
//     opacity: 1;
// }

// a.flag span {
//     margin-right: 5px;
//     font-size: 15px;
//     vertical-align: middle;
// }

// #goog-gt-tt {
//     display: none !important;
// }

// .goog-te-banner-frame {
//     display: none !important;
// }

// .goog-te-menu-value:hover {
//     text-decoration: none !important;
// }

// body {
//     top: 0 !important;
// }

// #google_translate_element2 {
//     display: none !important;
// }